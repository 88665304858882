import { SAS, SASU } from 'constants/companies';

const initialValues = (values, eurl_sasu) => {
  const companyDiffSASAndSASU = eurl_sasu !== SAS && eurl_sasu !== SASU;
  return {
    id: values.id || undefined,
    eurl_sasu,
    // user info
    personne_physique_morale: values.personne_physique_morale || '',
    partner_title: values.partner_title || '',
    partner_name: values.partner_name || '',
    partner_firstname: values.partner_firstname || '',
    partner_email: values.partner_email || '',
    partner_date_of_birth: values.partner_date_of_birth || null,
    partner_place_of_birth: values.partner_place_of_birth || '',
    partner_place_of_birth_zipcode: values.partner_place_of_birth_zipcode || '',
    partner_country_of_birth: values.partner_country_of_birth || '',
    partner_state_of_birth: values.partner_state_of_birth || '',
    partner_nationality: values.partner_nationality || '',
    partner_place_of_living: values.partner_place_of_living || '',
    partner_additional_address: values.partner_additional_address || '',
    partner_city: values.partner_city || '',
    partner_zipcode: values.partner_zipcode || '',
    partner_country: {
      id: '250',
      name_fr: 'France',
      UE: 'True',
      alpha2: 'FR',
      alpha3: 'FRA',
    },
    // partner_country: values.partner_country || '',
    partner_father_name: values.partner_father_name || '',
    partner_father_firstname: values.partner_father_firstname || '',
    partner_mother_name: values.partner_mother_name || '',
    partner_mother_firstname: values.partner_mother_firstname || '',
    partner_family_situation: values.partner_family_situation || '',

    partnerSecuNumber: values.partnerSecuNumber || '',
    partnerProtectionSocial: values.partnerProtectionSocial || '',
    partnerProtectionSocialDetail: values.partnerProtectionSocialDetail || '',

    // wedding info
    partner_spouse_title: values.partner_spouse_title || '',
    partner_spouse_name: values.partner_spouse_name || '',
    partner_spouse_firstname: values.partner_spouse_firstname || '',
    partner_spouse_date_naissance: values.partner_spouse_date_naissance || null,
    partner_spouse_lieu_naissance: values.partner_spouse_lieu_naissance || '',
    partner_wedding_date: values.partner_wedding_date || null,
    partner_wedding_place: values.partner_wedding_place || '',
    partner_spouse_adress: values.partner_spouse_adress || '',
    partner_spouse_city: values.partner_spouse_city || '',
    partner_spouse_zipcode: values.partner_spouse_zipcode || '',
    partner_spouse_additional_adress:
      values.partner_spouse_additional_adress || '',
    partner_spouse_email: values.partner_spouse_email || '',
    spouse_effective_date: values.spouse_effective_date || null,
    spouse_position: values.spouse_position || '',
    spouse_secu_number: companyDiffSASAndSASU
      ? values.spouse_secu_number || ''
      : '',

    // Info wedding prenup

    partner_wedding_prenup: values.partner_wedding_prenup || '',
    partner_wedding_prenup_type: values.partner_wedding_prenup_type || '',
    partner_wedding_prenup_date: values.partner_wedding_prenup_date || null,
    partner_wedding_prenup_place: values.partner_wedding_prenup_place || '',
    partner_wedding_prenup_officer_office_city:
      values.partner_wedding_prenup_officer_office_city || '',
    partner_wedding_prenup_officer_office_zipcode:
      values.partner_wedding_prenup_officer_office_zipcode || '',
    partner_wedding_prenup_officer_office_place:
      values.partner_wedding_prenup_officer_office_place || '',
    partner_wedding_prenup_officer_office_address:
      values.partner_wedding_prenup_officer_office_address || '',
    partner_wedding_prenup_officer_office_name:
      values.partner_wedding_prenup_officer_office_name || '',

    // Info Pacte
    pacs_type: values.pacs_type || '',
    pacs_spouse_title: values.pacs_spouse_title || '',
    pacs_date: values.pacs_date || null,
    pacs_place: values.pacs_place || '',
    pacs_spouse_name: values.pacs_spouse_name || '',
    pacs_spouse_firstname: values.pacs_spouse_firstname || '',
    pacs_spouse_lieu_naissance: values.pacs_spouse_lieu_naissance || '',
    pacs_spouse_date_naissance: values.pacs_spouse_date_naissance || null,
    pacs_partner_adress: values.pacs_partner_adress || '',
    pacs_partner_city: values.pacs_partner_city || '',
    pacs_partner_zipcode: values.pacs_partner_zipcode || '',
    pacs_partner_additional_adress: values.pacs_partner_additional_adress || '',
    pacs_partner_email: values.pacs_partner_email || '',
    pacs_effective_date: values.pacs_effective_date || null,
    pacs_position: values.pacs_position || '',
    pacs_secu_number: companyDiffSASAndSASU
      ? values.pacs_secu_number || ''
      : '',

    // info cohabitant

    partner_cohabitant_title: values.partner_cohabitant_title || '',
    partner_cohabitant_name: values.partner_cohabitant_name || '',
    partner_cohabitant_firstname: values.partner_cohabitant_firstname || '',
    partner_cohabitant_email: values.partner_cohabitant_email || '',
    partner_cohabitant_birthday: values.partner_cohabitant_birthday || null,
    partner_cohabitant_place_of_birth:
      values.partner_cohabitant_place_of_birth || '',
    partner_cohabitant_adress: values.partner_cohabitant_adress || '',
    partner_cohabitant_city: values.partner_cohabitant_city || '',
    partner_cohabitant_zipcode: values.partner_cohabitant_zipcode || '',
    partner_cohabitant_additional_adress:
      values.partner_cohabitant_additional_adress || '',
    cohabitant_effective_date: values.cohabitant_effective_date || null,
    cohabitant_position: values.cohabitant_position || '',
    cohabitant_secu_number: companyDiffSASAndSASU
      ? values.cohabitant_secu_number || ''
      : '',

    // Info PM
    forme_juridique_personne_morale:
      values.forme_juridique_personne_morale || '',
    capital_personne_morale: values.capital_personne_morale || '',
    raison_sociale_actionnaire_personne_morale_sasu:
      values.raison_sociale_actionnaire_personne_morale_sasu || '',
    adresse_actionnaire_personne_morale_sasu:
      values.adresse_actionnaire_personne_morale_sasu || '',
    additional_address_actionnaire_personne_morale_sasu:
      values.additional_address_actionnaire_personne_morale_sasu || '',
    ville_actionnaire_personne_morale_sasu:
      values.ville_actionnaire_personne_morale_sasu || '',
    code_postal_actionnaire_personne_morale_sasu:
      values.code_postal_actionnaire_personne_morale_sasu || '',
    rcs_actionnaire_personne_morale_sasu:
      values.rcs_actionnaire_personne_morale_sasu || '',
    ville_rcs_actionnaire_personne_morale_sasu:
      values.ville_rcs_actionnaire_personne_morale_sasu || '',
  };
};
// eslint-disable-next-line
export { initialValues };
