export const MULTI_PARTNER = 'a_plusieurs';
export const SINGLE = 'célibataire';
export const MARIED = 'marié(e)';
export const DIVORCED = 'divorcé(e)';
export const PACTE = 'pacsé(e)';
export const CONCUBIN = 'concubin';
export const WIDOW = 'veuf(ve)';
export const YES = 'oui';
export const NO = 'non';
export const PM = 'personne_morale';
export const PP = 'personne_physique';
export const CAC_PM = 'morale';
export const CAC_PP = 'physique';
export const SARL = 'SARL';
export const SASU = 'SASU';
export const SAS = 'SAS';
export const EURL = 'EURL';
export const SA = 'SA';
export const SC = 'SC';
export const SCA = 'SCA';
export const SCS = 'SCS';
export const MONSIEUR = 'M.';
export const MADAME = 'Mme';
export const TIERS = 'un_tiers';
export const PARTNER = "l_associe_de_l'EURL";
export const REQUIRED = 'Champs requis';
export const DATE_RIQUIRED = 'Une date est necessaire';
export const DATE_CONSTRAINTE = `la date doit être antérieure à la date d'aujourd'hui`;
export const BANK = 'banque';
export const NOTARIE = 'notaire';
export const BBF_NOTARIE = 'be_business_focus';
export const HEAD_OFFICE_AT_SEO = 'chez_gerant';
export const HEAD_OFFICE_IN_LOCAL = 'local';
export const HEAD_OFFICE_IN_DOMICILIATION = 'en_domiciliation';
export const OWNER = 'Proprietaire';
export const TENANT = 'Locataire';
export const OTHER = 'autre';
export const IS = 'is';
export const IR = 'ir';

export const POSITION_EMPLOYEE = 'employee';
export const POSITION_PARTNER = 'partner';
export const POSITION_COLLABORATER = 'collaborater';
export const POSITION_NOT_CONCERNED = 'not_concerned'; // ~ Non concerné(e) in UI

// form - Régime de TVA
export const VAT_SIMPLIFIED = 'vat_simplified';
export const VAT_MINI_REAL = 'vat_mini_real';
export const VAT_REAL_NORMAL = 'vat_real_normal';
export const FRANCHISE_BASE = 'franchise_base';
export const ACTIVITY_NOT_SUBMITTED = 'activity_not_submitted';

export const pacsTypes = [
  {
    label: 'De la séparation des patrimoines',
    value: 'de_la_separation_des_patrimoines',
  },
  { label: "De l'indivision", value: "de_l'indivision" },
];
export const headOfficeLocations = [
  { label: 'Chez le gérant', value: HEAD_OFFICE_AT_SEO },
  { label: 'dans un local', value: HEAD_OFFICE_IN_LOCAL },
  { label: 'En domiciliation', value: HEAD_OFFICE_IN_DOMICILIATION },
];
export const headOfficeLocationsPresident = [
  { label: 'Chez le président', value: HEAD_OFFICE_AT_SEO },
  { label: 'dans un local', value: HEAD_OFFICE_IN_LOCAL },
  { label: 'En domiciliation', value: HEAD_OFFICE_IN_DOMICILIATION },
];
export const taxregime = [
  { label: 'Impôt sur les sociétés', value: 'is' },
  { label: 'Impôt sur le revenu', value: 'ir' },
];
export const proprietaireLocataire = [
  { label: 'Proprietaire', value: OWNER },
  { label: 'Locataire', value: TENANT },
];
export const personTypes = [
  { label: 'Personne physique', value: PP },
  { label: 'Personne morale', value: PM },
];
export const cacTypes = [
  { label: 'Personne physique', value: CAC_PP },
  { label: 'Personne morale', value: CAC_PM },
];
export const genders = [
  { label: 'Monsieur', value: MONSIEUR },
  { label: 'Madame', value: MADAME },
];
export const familySituations = [
  { label: 'Célibataire', value: 'célibataire' },
  { label: 'Marié(e)', value: 'marié(e)' },
  { label: 'Divorcé(e)', value: 'divorcé(e)' },
  { label: 'Pacsé(e)', value: 'pacsé(e)' },
  { label: 'Veuf(ve)', value: 'veuf(ve)' },
  { label: 'Concubin', value: 'concubin' },
];

export const weddingStatuts = [
  { label: 'Séparation de biens', value: 'separation_de_biens' },
  { label: 'Communauté universelle', value: 'communaute_universelle' },
  { label: 'Communauté de biens', value: 'communaute_de_biens' },
];

export const regimeSociale = [
  { label: 'général', value: 'régime général' },
  { label: 'agricole', value: 'régime agricole' },
  {
    label: 'Non salarié/agricole',
    value: 'régime non salarié non agricole',
  },
  { label: 'ENIM', value: 'ENIM' },
  { label: 'Autre', value: OTHER },
];

export const juridicForms = [
  { label: SARL, value: SARL },
  { label: SASU, value: SASU },
  { label: SAS, value: SAS },
  { label: EURL, value: EURL },
  { label: SA, value: SA },
  { label: SC, value: SC },
  { label: SCA, value: SCA },
  { label: SCS, value: SCS },
];

export const yesNo = [
  { label: YES, value: YES },
  { label: NO, value: NO },
];

export const dureeExercieCAC = [
  { label: '3 exercices', value: '3' },
  { label: '6 exercices', value: '6' },
];

export const numberOfContract = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
];

export const FIVE_FIRST_NUMBER_LIST = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' },
];

export const debutActivities = [
  { label: 'Immediatement', value: 'immediatement' },
  { label: 'Plus tard', value: 'date_externe' },
  { label: 'Sans activité', value: 'sans_activite' },
];

export const kits = [
  {
    kitId: 'basiquesoc',
    price: '108',
    title: `Création d'entreprise - Kit Créa Starter`,
    subtitle: 'Nous ne générons que des projets de documents',
    descriptions: [
      `<b>Génération UNIQUEMENT des projets de documents pour votre dossier de constitution :</b>`,
      `<ul style="list-style-type: none">
        <li style="margin-bottom: 8px">
          Projet de statuts
        </li>
        <li style="margin-bottom: 8px">
          Projet d'annonce légale
        </li>
        <li style="margin-bottom: 8px">
          Projet d'attestation de domiciliation
        </li>
        <li style="margin-bottom: 8px">
          Projet d'attestation de non-condamnation
        </li>
      </ul>`,
      `<b style="color:red">Il n'y a pas d'accompagnement à l'immatricualtion de votre société</b>`,
      `<b style="color:red">Les frais administratifs ne sont donc PAS
      INCLUS dans cette formule.</b>`,
    ],
  },
  {
    kitId: 'kit3soc',
    price: '414',
    title: `Création d'entreprise - Kit Créa Efficience`,
    subtitle: 'Accompagnement SANS dépôt des fonds',
    descriptions: [
      `<b>Génération et vérification des documents finaux de votre dossier de constitution :</b>`,
      `
      <ul style="list-style-type: none">
        <li style="margin-bottom: 8px">
          Statuts PDF signés (via Docusign)
        </li>
        <li style="margin-bottom: 8px">
          Attestation de domiciliation
        </li>
        <li style="margin-bottom: 8px">
          Attestation de non-condamnation
        </li>
        <li style="margin-bottom: 8px">
          Déclaration bénéficiaires effectif•s (MBE)
        </li>
      </ul>`,
      `<b style="color: red">Charge à l'entrepreneur de réaliser son dépôt des fonds avec sa banque ou son notaire</b>`,
      `<span style="color: blue">*Frais administratifs inclus*</span> Annonce légale`,
      `<span style="color: blue">*Frais administratifs inclus*</span> Dépôt et suivi du dossier`,
      `<b>Accompagnement par mail, chat et téléphone pour l'immatriculation INCLUS avec dépôt de votre dossier</b>`,
      `Obtention de votre Kbis en 3 jours ouvrés en moyenne après dépôt de votre dossier.`,
    ],
  },
  {
    kitId: 'kit4soc',
    price: '534',
    title: `Création d'entreprise - Kit Créa Plus`,
    subtitle: 'Accompagnement AVEC dépôt des fonds inclus',
    descriptions: [
      `<b>Génération et vérification des documents finaux de votre dossier de constitution :</b>`,
      `
      <ul style="list-style-type: none">
        <li style="margin-bottom: 8px">
          Statuts PDF signés (via Docusign)
        </li>
        <li style="margin-bottom: 8px">
          Attestation de domiciliation
        </li>
        <li style="margin-bottom: 8px">
          Attestation de non-condamnation
        </li>
        <li style="margin-bottom: 8px">
          Déclaration bénéficiaires effectif's (MBE)
        </li>
      </ul>`,
      `<b style="color: #24a624">Vous réalisez votre dépôt de capital directement par virement bancaire à notre notaire partenaire</b>`,
      `<span style="color: blue">*Frais administratifs inclus*</span> Annonce légale`,
      `<span style="color: blue">*Frais administratifs inclus*</span> Dépôt et suivi du dossier`,
      `<b>Accompagnement par mail, chat et téléphone pour l'immatriculation INCLUS avec dépôt de votre dossier</b>`,
      `Obtention de votre Kbis en 3 jours ouvrés en moyenne après dépôt de votre dossier.`,
    ],
  },
  {
    kitId: 'basiquesci',
    price: '108',
    title: `Création d'entreprise - Kit Créa Starter`,
    subtitle: 'Nous ne générons que des projets de documents',
    descriptions: [
      `<b>Génération UNIQUEMENT des projets de documents pour votre dossier de constitution :</b>`,
      `<ul style="list-style-type: none">
        <li style="margin-bottom: 8px">
          Projet de statuts
        </li>
        <li style="margin-bottom: 8px">
          Projet d'annonce légale
        </li>
        <li style="margin-bottom: 8px">
          Projet d'attestation de domiciliation
        </li>
        <li style="margin-bottom: 8px">
          Projet d'attestation de non-condamnation
        </li>
      </ul>`,
      `<b style="color:red">Il n'y a pas d'accompagnement à l'immatricualtion de votre société</b>`,
      `<b style="color:red">Les frais administratifs ne sont donc PAS
      INCLUS dans cette formule.</b>`,
    ],
  },
  {
    kitId: 'kit3sci',
    price: '414',
    title: `Création d'entreprise - Kit Créa Efficience`,
    subtitle: 'Accompagnement SANS dépôt des fonds',
    descriptions: [
      `<b>Génération et vérification des documents finaux de votre dossier de constitution :</b>`,
      `
      <ul style="list-style-type: none">
        <li style="margin-bottom: 8px">
          Statuts PDF signés (via Docusign)
        </li>
        <li style="margin-bottom: 8px">
          Attestation de domiciliation
        </li>
        <li style="margin-bottom: 8px">
          Attestation de non-condamnation
        </li>
        <li style="margin-bottom: 8px">
          Déclaration bénéficiaires effectif•s (MBE)
        </li>
      </ul>`,
      `<b style="color: red">Charge à l'entrepreneur de réaliser son dépôt des fonds avec sa banque ou son notaire</b>`,
      `<span style="color: blue">*Frais administratifs inclus*</span> Annonce légale`,
      `<span style="color: blue">*Frais administratifs inclus*</span> Dépôt et suivi du dossier`,
      `<b>Accompagnement par mail, chat et téléphone pour l'immatriculation INCLUS avec dépôt de votre dossier</b>`,
      `Obtention de votre Kbis en 3 jours ouvrés en moyenne après dépôt de votre dossier.`,
    ],
  },
  {
    kitId: 'kit4sci',
    price: '534',
    title: `Création d'entreprise - Kit Créa Plus`,
    subtitle: 'Accompagnement AVEC dépôt des fonds inclus',
    descriptions: [
      `<b>Génération et vérification des documents finaux de votre dossier de constitution :</b>`,
      `
      <ul style="list-style-type: none">
        <li style="margin-bottom: 8px">
          Statuts PDF signés (via Docusign)
        </li>
        <li style="margin-bottom: 8px">
          Attestation de domiciliation
        </li>
        <li style="margin-bottom: 8px">
          Attestation de non-condamnation
        </li>
        <li style="margin-bottom: 8px">
          Déclaration bénéficiaires effectif's (MBE)
        </li>
      </ul>`,
      `<b style="color: #24a624">Vous réalisez votre dépôt de capital directement par virement bancaire à notre notaire partenaire</b>`,
      `<span style="color: blue">*Frais administratifs inclus*</span> Annonce légale`,
      `<span style="color: blue">*Frais administratifs inclus*</span> Dépôt et suivi du dossier`,
      `<b>Accompagnement par mail, chat et téléphone pour l'immatriculation INCLUS avec dépôt de votre dossier</b>`,
      `Obtention de votre Kbis en 3 jours ouvrés en moyenne après dépôt de votre dossier.`,
    ],
  },
];

export const positionOptions = [
  { label: 'Salarié', value: POSITION_EMPLOYEE },
  { label: 'Associé', value: POSITION_PARTNER },
  { label: 'Collaborateur', value: POSITION_COLLABORATER },
  { label: 'Non concerné(e)', value: POSITION_NOT_CONCERNED },
];

export const regimeVATOptions = [
  {
    label: 'Régime simplifié - déclaration annuelle',
    value: VAT_SIMPLIFIED,
  },
  {
    label: 'Régime mini-réel - déclaration trimestrielle',
    value: VAT_MINI_REAL,
  },
  {
    label:
      'Régime réel normal - déclaration mensuelle (obligatoire en cas d’achat intracommunautaire)',
    value: VAT_REAL_NORMAL,
  },
  {
    label: 'Franchise en base',
    value: FRANCHISE_BASE,
  },
  {
    label: 'Activité non soumise',
    value: ACTIVITY_NOT_SUBMITTED,
  },
];
