import React from 'react';
import { EURL, SAS, SASU } from 'constants/companies';
import { genders, pacsTypes } from 'constants/usersContants';
import { Field, useFormikContext } from 'formik';
import { TextField } from 'formik-material-ui';
import getUserInfoPositionOptions from 'helpers/userInfo';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import QuestionMark from 'components/commons/QuestionMark';
import { Typography } from '@material-ui/core';
import SmallRadio from '../commons/smallRadio';
import Adresse from '../commons/adress';

function InfoPacteCEO({ classes, eurl_sasu }) {
  const { values } = useFormikContext();
  const { director_pacs_type, director_pacse_title, director_pacse_position } =
    values;

  return (
    <>
      {eurl_sasu === EURL && (
        <h2 className={classes.subTitle}>
          S’agissant des partenaires liés par un PACS, ils peuvent en principe
          souscrire librement des parts sociales d’une EURL en toute
          indépendance, sauf clause contraire prévue dans la convention de PACS.
          <br />
          Aucun formalisme n’est nécessaire lorsque les parts sont financées par
          des apports personnels ne faisant pas partie de la communauté, et quel
          que soit le régime matrimonial.
        </h2>
      )}
      <h2 className={classes.subTitle}> Pacsé(e) sous quel régime ?</h2>
      <SmallRadio
        classes={classes}
        options={pacsTypes}
        value={director_pacs_type}
        name="director_pacs_type"
        style={{ marginBottom: '2%' }}
      />
      {/* info pacse */}
      <h2 className={classes.subTitle}>Informations sur votre conjoint.</h2>
      <SmallRadio
        classes={classes}
        options={genders}
        value={director_pacse_title}
        name="director_pacse_title"
        style={{ marginBottom: '2%' }}
      />
      <Field
        component={TextField}
        type="string"
        label="Nom du conjoint *"
        name="director_pacse_name"
        className={classes.nameTextField}
        variant="outlined"
        size="small"
        inputProps={{ style: { textTransform: 'capitalize' } }}
      />
      <Field
        component={TextField}
        type="string"
        label="Prénom du conjoint *"
        name="director_pacse_firstname"
        className={classes.nameTextField}
        variant="outlined"
        size="small"
        inputProps={{ style: { textTransform: 'capitalize' } }}
      />
      <Field
        component={TextField}
        type="string"
        label="Adresse courriel *"
        name="director_pacse_email"
        className={classes.nameTextField}
        variant="outlined"
        size="small"
      />
      {eurl_sasu !== SAS && eurl_sasu !== SASU && (
        <Field
          component={TextField}
          type="string"
          label="N° sécurité sociale *"
          name="director_pacse_secu_number"
          className={classes.nameTextField}
          autoComplete="off"
          variant="outlined"
          size="small"
        />
      )}
      <Field
        component={KeyboardDatePicker}
        clearable
        label="Date de naissance du conjoint *"
        placeholder="01/01/1990"
        format="dd/MM/yyyy"
        maxDate={new Date()}
        name="director_pacse_birthday"
        className={classes.nameTextField}
        inputVariant="outlined"
        size="small"
      />
      <Field
        component={TextField}
        type="string"
        label="Lieu de naissance du conjoint *"
        name="director_pacse_place_of_birth"
        className={classes.nameTextField}
        variant="outlined"
        size="small"
      />
      <Adresse
        classes={classes}
        title="Adresse *"
        address="director_pacse_adress"
        city="director_pacse_city"
        zipCode="director_pacse_zipcode"
        department={null}
        country={null}
        cityLabel="Ville *"
        nationality={null}
        disabledZipCode={true}
        disabledCity={true}
        additionalAddress="director_pacse_additional_adress"
      />
      <h2 className={classes.subTitle}>
        {`Statut au sein de l'entreprise *`}
        <QuestionMark
          classes={classes}
          content={
            <>
              <Typography>
                <b>Salarié</b>
                {`: statut pouvant être choisi par le conjoint qui
        exerce une activité professionnelle régulière dans l’entreprise en
        percevant un salaire. Comme pour tout salarié, une déclaration
        préalable à l’embauche doit avoir été effectuée auprès de
        l’organisme social compétent pour l’entreprise`}
              </Typography>
              <br />
              <Typography>
                <b>Associé (si société)</b>
                {`: statut pouvant être choisi par le
          conjoint du dirigeant d'une société qui exerce une activité
          professionnelle régulière dans l'entreprise et détient des parts
          sociales dans la société. Les formalités ont été réalisées au
          niveau de la société`}
              </Typography>
              <br />
              <Typography>
                <b>Collaborateur</b>
                {` : statut pouvant être choisi par le conjoint d’un entrepreneur individuel, du 
          gérant associé unique d’une SARL unipersonnelle (EURL), ou du gérant associé majoritaire d’une 
          SARL ou SELARL, qui exerce une activité professionnelle régulière dans l’entreprise sans être 
          rémunéré, et sans être associé. Le conjoint collaborateur ne perçoit pas de rémunération et n’a 
          pas de contrat de travail. Ce statut est limité à une durée totale de 5 ans.`}
              </Typography>
            </>
          }
        />
      </h2>
      <Field
        component={KeyboardDatePicker}
        clearable
        label="Date d’effet *"
        placeholder="01/01/1990"
        format="dd/MM/yyyy"
        maxDate={new Date()}
        name="director_pacse_effective_date"
        className={classes.nameTextField}
        inputVariant="outlined"
        size="small"
      />
      <SmallRadio
        classes={classes}
        options={getUserInfoPositionOptions(eurl_sasu)}
        value={director_pacse_position}
        name="director_pacse_position"
        style={{ marginBottom: '2%' }}
      />
    </>
  );
}

export default InfoPacteCEO;
