const KitPreview = {
  basiquesoc: {
    heading: [`Création d'entreprise - Kit Créa Starter`],
    description: 'Création des statuts',
    services: [
      'Statuts',
      "Projet d'annonce légale",
      'Attestation de domiciliation',
      'Attestation de non condamnation',
      'Récupération de vos statuts en pdf ',
      'Assistance par mail',
    ],
    price: '108 TTC',
  },

  kit3soc: {
    heading: [`Création d'entreprise - Kit Créa Efficience`],
    description: 'Création des statuts – annonce légale – immatriculation',
    services: [
      'Statuts',
      'Attestation de domiciliation',
      'Attestation de non condamnation',
      'Récupération de vos statuts en pdf ',
      'Attestation de dépôt des fonds fournie par vos soins',
      'Annonce légale par BBF',
      "Dépôt et suivi du dossier d'immatriculation",
      'Kbis sous 72h ouvrées',
      'Suivi téléphonique',
      'Déclaration relative au(x) bénéficiaire(s) effectif(s) (MBE)',
    ],
    price: '414 TTC',
  },

  kit4soc: {
    heading: [`Création d'entreprise - Kit Créa Plus`],
    description:
      'Création des statuts – annonce légale – immatriculation – dépôt des fonds ',
    services: [
      'Statuts',
      'Attestation de domiciliation',
      'Attestation de non condamnation',
      'Récupération de vos statuts en pdf ',
      'Attestation de dépôt des fonds par BBF',
      'Annonce légale par BBF',
      "Dépôt et suivi du dossier d'immatriculation",
      'Kbis sous 72h ouvrées',
      'Suivi téléphonique',
      'Déclaration relative au(x) bénéficiaire(s) effectif(s) (MBE)',
    ],
    price: '534 TTC',
  },

  auto: {
    heading: [],
    description: '',
    services: [
      'Kit accompagnement',
      'Immatriculation',
      "Dossier d'immatriculation",
      'Accompagnement par mail',
      'Immatriculation',
      'Numéro SIRET-INSEE en 48h',
    ],
    price: '30 euros ht',
  },

  ei: {
    heading: [],
    description: '',
    services: [
      'Kit accompagnement entrepreneur individuel',
      'Immatriculation',
      "Dossier d'immatriculation",
      'Accompagnement par mail',
      "Dépôt et suivi du dossier d'immatriculation",
      'Numéro RCS – RM en 48 h',
    ],
    price: '50 euros HT',
  },

  eirl: {
    heading: [],
    description: '',
    services: [
      'Kit accompagnement EIRL',
      'Constitution et immatriculation',
      "Constitution et dossier d'immatriculation",
      'Accompagnement par mail',
      "Dépôt et suivi du dossier d'immatriculation",
      'Numéro RCS – RM en 48 h',
    ],
    price: '50 euros HT',
  },

  basiquesci: {
    heading: ['Kit statuts et dossier juridique'],
    description: 'Création des statuts',
    services: [
      'Statuts',
      "Projet d'annonce légale",
      'Projet d’attestation de domiciliation',
      'Projet d’attestation de non-condamnation ',
      'Récupération de vos statuts en pdf ',
      'Assistance par mail',
    ],
    price: '108 TTC',
  },

  kit3sci: {
    heading: ['Kit statuts et immatriculation'],
    description: 'Création des statuts – annonce légale – immatriculation',
    services: [
      'Statuts',
      'Attestation de domiciliation',
      'Attestation de non condamnation',
      'Récupération de vos statuts en pdf ',
      'Attestation de dépôt des fonds fournie par vos soins',
      'Annonce légale par BBF',
      "Dépôt et suivi du dossier d'immatriculation",
      'Kbis sous 72h ouvrées',
      'Suivi téléphonique',
      'Déclaration relative au(x) bénéficiaire(s) effectif(s) (MBE)',
    ],
    price: '414 TTC',
  },

  kit4sci: {
    heading: ["Kit on s'occupe de tout"],
    description:
      'Création des statuts – annonce légale – immatriculation – dépôt des fonds',
    services: [
      'Statuts',
      'Attestation de domiciliation',
      'Attestation de non condamnation',
      'Récupération de vos statuts en pdf ',
      'Attestation de dépôt des fonds par BBF',
      'Annonce légale par BBF',
      "Dépôt et suivi du dossier d'immatriculation",
      'Kbis sous 72h ouvrées',
      'Suivi téléphonique',
      'Déclaration relative au(x) bénéficiaire(s) effectif(s) (MBE)',
    ],
    price: '534 TTC',
  },

  basiqueassoc: {
    heading: ['Kit Statut', 'Votre dossier juridique'],
    description: 'Création des statuts',
    services: [
      'Statuts',
      "Dossier d'enregistrement",
      "Projet d'annonce légale",
      'Récupération de vos statuts en pdf ',
    ],
    price: '108 euros HT',
  },

  kit1assoc: {
    heading: ["Kit on s'occupe de tout"],
    description: 'Création des statuts – immatriculation',
    services: [
      'Statuts',
      "Procés-verbal de l'assemblée constitutive",
      'Récupération de vos statuts en pdf ',
      'Signature électronique',
      'Immatriculation en ligne',
      'Dépôt et suivi du dossier à la préfecture',
      'Obtention du SIREN sous 48h',
      'Suivi téléphonique',
    ],
    price: '199 euros HT',
  },

  kit2assoc: {
    heading: ["Kit on s'occupe de tout"],
    description: 'Création des statuts – annonce légale – immatriculation',
    services: [
      'Statuts',
      'Signature électronique',
      'Dépôt et suivi du dossier à la préfecture',
      'Obtention du SIREN sous 48h',
      'Récupération de vos statuts en pdf ',
      'Annonce légale réalisée par BBF',
      'Suivi téléphonique',
    ],
    price: '299 euros HT',
  },
};

export default KitPreview;
